var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, FormControlLabel, Radio, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DifferenceMode } from '../../enum';
import { REPORT_ROUTER_URL } from '../../router/constants';
import { useSummaryChunkSize, useSummaryReportAPI } from '../annual/selectors';
import { useUserPermission } from '../app/selectors';
import { Autocomplete, Outlet, RadioGroup } from '../common';
import { PageBody, SubTitle } from '../layout/styled';
import { REPORT_OPTIONS } from './constants';
import { useReportOutletContext, useReportType } from './hooks';
import { useAddScenarioAPI, useDisplayModeParams, useExistMultiskillMode, useReportAPI, useViewTypeParams, } from './selectors';
export var ViewType;
(function (ViewType) {
    ViewType[ViewType["Table"] = 0] = "Table";
    ViewType[ViewType["Graphical"] = 1] = "Graphical";
})(ViewType || (ViewType = {}));
var VIEW_OPTIONS = [
    { name: 'Table view', id: ViewType.Table },
    { name: 'Graphical view', id: ViewType.Graphical },
];
export var DisplayMode;
(function (DisplayMode) {
    DisplayMode["All"] = "ALL";
    DisplayMode["Single"] = "SINGLE_SKILL";
    DisplayMode["Multi"] = "MULTI_SKILL";
})(DisplayMode || (DisplayMode = {}));
var DISPLAY_MODE_OPTIONS = [
    { name: 'All skills', id: DisplayMode.All },
    { name: 'Single skill only', id: DisplayMode.Single },
    { name: 'Multi-skill only', id: DisplayMode.Multi },
];
export var ReportControl = function (props) {
    var _a;
    var reportData = useReportAPI()[0];
    var _b = useViewTypeParams(), viewType = _b[0], setViewType = _b[1];
    var _c = useDisplayModeParams(), displayMode = _c[0], setDisplayMode = _c[1];
    var navigate = useNavigate();
    var reportType = useReportType();
    var isDisabledDaily = !((_a = reportData.data) === null || _a === void 0 ? void 0 : _a.hasDaily) || viewType === ViewType.Graphical || !!props.disableDaily;
    var isDisabledGraphical = reportType === REPORT_ROUTER_URL.Daily || displayMode !== DisplayMode.Single || !!props.disableGraphical;
    var existMultiskillMode = useExistMultiskillMode();
    useEffect(function () {
        if (reportData.data && isDisabledDaily && reportType === REPORT_ROUTER_URL.Daily) {
            navigate(REPORT_ROUTER_URL.Annual);
        }
    }, [isDisabledDaily, navigate, reportData.data, reportType]);
    useEffect(function () {
        if (isDisabledGraphical && viewType === ViewType.Graphical) {
            navigate(REPORT_ROUTER_URL.Annual);
        }
    }, [isDisabledGraphical, navigate, viewType]);
    return (_jsxs(Stack, __assign({ direction: "row", gap: 5, alignItems: "center", paddingBottom: 4 }, { children: [props.children, existMultiskillMode && (_jsx(Autocomplete, { sx: { marginLeft: 'auto', width: 200 }, "data-testid": "displayMode", value: displayMode, onChange: setDisplayMode, options: DISPLAY_MODE_OPTIONS, getOptionDisabled: function (o) { return o.id !== DisplayMode.Single && viewType === ViewType.Graphical; } })), _jsx(Autocomplete, { sx: { marginLeft: existMultiskillMode ? '' : 'auto', width: 200 }, "data-testid": "viewType", value: viewType, onChange: setViewType, options: VIEW_OPTIONS, getOptionDisabled: function (o) { return o.id === ViewType.Graphical && isDisabledGraphical; } }), _jsx(Autocomplete, { sx: { width: 200 }, "data-testid": "reportType", value: reportType, onChange: function (route) {
                    var url = "".concat(route, "?viewType=").concat(viewType);
                    if (existMultiskillMode) {
                        url += "&displayMode=".concat(displayMode);
                    }
                    navigate(url);
                }, options: reportData.data ? REPORT_OPTIONS : [], getOptionLabel: function (o) { var _a, _b; return (_b = o["name".concat((_a = reportData.data) === null || _a === void 0 ? void 0 : _a.planType)]) !== null && _b !== void 0 ? _b : o.name; }, getOptionDisabled: function (o) { return o.id === REPORT_ROUTER_URL.Daily && isDisabledDaily; }, loading: reportData.requesting })] })));
};
var AnnualControl = function (props) {
    var t = useTranslation('report').t;
    var viewType = useViewTypeParams()[0];
    var reportType = useReportType();
    var _a = useAddScenarioAPI(), addScenarioData = _a[0], addScenario = _a[1], clearAddedData = _a[2];
    var _b = useReportAPI(), reportData = _b[0], fetchReport = _b[1];
    var _c = useSummaryReportAPI(), fetchSummaryData = _c[1];
    var permission = useUserPermission();
    var displayMode = useDisplayModeParams()[0];
    var summaryChunkSize = useSummaryChunkSize();
    var hasAddPermission = permission && reportData.data && (!permission.scenario || reportData.data.scenarios.length < permission.scenario);
    useEffect(function () {
        if (addScenarioData.data) {
            fetchReport();
            fetchSummaryData();
        }
        return clearAddedData;
    }, [addScenarioData.data, fetchSummaryData, fetchReport, clearAddedData]);
    var handleAddScenario = function () {
        addScenario();
    };
    if (viewType !== ViewType.Table || reportType !== REPORT_ROUTER_URL.Annual)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ variant: "contained", sx: { whiteSpace: 'nowrap' }, disabled: !!addScenarioData.requesting || !hasAddPermission, onClick: handleAddScenario }, { children: t('resourcing.addScenario') })), displayMode !== DisplayMode.All && summaryChunkSize > 1 && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "subtitle2", whiteSpace: "nowrap" }, { children: t('resourcing.showScenarioDiff') })), _jsxs(RadioGroup, __assign({ row: true }, { children: [_jsx(FormControlLabel, { onClick: function () { var _a; return (_a = props.setDifferenceMode) === null || _a === void 0 ? void 0 : _a.call(props, DifferenceMode.Absolute); }, checked: props.differenceMode === DifferenceMode.Absolute, control: _jsx(Radio, {}), label: t('resourcing.absoluteValue') }), _jsx(FormControlLabel, { onClick: function () { var _a; return (_a = props.setDifferenceMode) === null || _a === void 0 ? void 0 : _a.call(props, DifferenceMode.Percentage); }, checked: props.differenceMode === DifferenceMode.Percentage, control: _jsx(Radio, {}), label: t('resourcing.percentages') })] }))] }))] }));
};
export var ResourcingLayout = function () {
    var context = useReportOutletContext();
    var _a = useState(), description = _a[0], setDescription = _a[1];
    var _b = useState(DifferenceMode.Absolute), differenceMode = _b[0], setDifferenceMode = _b[1];
    var t = useTranslation('report').t;
    return (_jsxs(PageBody, { children: [_jsx(SubTitle, { children: description !== null && description !== void 0 ? description : t('resourcing.description') }), _jsx(ReportControl, { children: _jsx(AnnualControl, { differenceMode: differenceMode, setDifferenceMode: setDifferenceMode }) }), _jsx(Outlet, { context: __assign(__assign({}, context), { setDescription: setDescription, isPercentages: differenceMode }) })] }));
};
