var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormControlLabel, Switch } from '@mui/material';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog, FormField, NativeNumberInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '../../common';
import { useFormlessContext } from '../../hooks';
import { useIsViewOnlyPlan } from '../../report/selectors';
import { useWizardSimpleContext } from '../useWizardSimpleContext';
import { StaffCalculate } from './types';
var RoleTableHeader = function (_a) {
    var disableSupportStaff = _a.disableSupportStaff, disableFinancial = _a.disableFinancial;
    var t = useTranslation('wizard', { keyPrefix: 'supportStaff' }).t;
    return (_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ align: "left" }, { children: t('role') })), !disableSupportStaff && _jsxs(TableCell, { children: [t('ratioStaffToAgents'), " "] }), !disableFinancial && _jsx(TableCell, { children: t('salary') }), !disableSupportStaff && _jsx(TableCell, { children: t('staffBaseOn') })] }) }));
};
var RoleTableBody = function (_a) {
    var _b, _c, _d;
    var disableSupportStaff = _a.disableSupportStaff, disableFinancial = _a.disableFinancial;
    var t = useTranslation('wizard', { keyPrefix: 'supportStaff' }).t;
    var queue = ((_b = useWizardSimpleContext()) !== null && _b !== void 0 ? _b : {}).queue;
    var _e = useState(false), showAttention = _e[0], setShowAttention = _e[1];
    var _f = (_c = useFormlessContext()) !== null && _c !== void 0 ? _c : {}, control = _f.control, setValue = _f.setValue;
    var currency = useWatch({ control: control, name: 'currency' });
    var roles = useWatch({ control: control, name: 'roles' });
    var isViewOnly = useIsViewOnlyPlan();
    var handleSwitchChange = function (item) { return function (event) {
        var _a = event.target, name = _a.name, checked = _a.checked;
        var newValue = checked ? StaffCalculate.FTE : StaffCalculate.Headcount;
        setValue(name, newValue, { shouldDirty: true });
        setShowAttention(!!item.ratio);
    }; };
    return (_jsxs(_Fragment, { children: [_jsx(TableBody, { children: (_d = queue === null || queue === void 0 ? void 0 : queue.roles) === null || _d === void 0 ? void 0 : _d.map(function (item, rowIdx) {
                    var _a;
                    return (!!rowIdx || !disableFinancial) && (_jsxs(TableRow, { children: [_jsx(TableCell, { align: "left", greyColor: true, value: item.role, sx: { fontWeight: 600 } }), !disableSupportStaff && !rowIdx && _jsx(TableCell, { greyColor: true }), !disableSupportStaff && !!rowIdx && (_jsx(TableCell, __assign({ padding: "none" }, { children: _jsx(FormField, __assign({ disableHelperText: true, name: "roles.".concat(rowIdx, ".ratio") }, { children: _jsx(NativeNumberInput, { disabled: isViewOnly, max: 1000, decimal: 0 }) })) }))), !disableFinancial && (_jsx(TableCell, __assign({ padding: "none" }, { children: _jsx(FormField, __assign({ disableHelperText: true, name: "roles.".concat(rowIdx, ".salary") }, { children: _jsx(NativeNumberInput, { disabled: isViewOnly, decimal: 2, min: 1, max: 10000000, placeholder: currency }) })) }))), !disableSupportStaff && !rowIdx && _jsx(TableCell, { greyColor: true }), !disableSupportStaff && !!rowIdx && (_jsxs(TableCell, __assign({ padding: "none" }, { children: [_jsx(FormControlLabel, { name: "roles.".concat(rowIdx, ".staffCalculate"), sx: { marginLeft: 0 }, control: _jsx(Switch, { disabled: isViewOnly, checked: ((_a = roles === null || roles === void 0 ? void 0 : roles[rowIdx]) === null || _a === void 0 ? void 0 : _a.staffCalculate) === StaffCalculate.FTE, onChange: handleSwitchChange(item) }), label: t('headcount'), labelPlacement: "start" }), "\u00A0 FTE"] })))] }, item.role));
                }) }), _jsx(ConfirmDialog, { open: showAttention, onClose: function () { return setShowAttention(!showAttention); }, title: t('attentionAlert.title'), message: t('attentionAlert.content'), cancelButtonText: "close" })] }));
};
export var RoleTable = function (props) {
    return (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(RoleTableHeader, __assign({}, props)), _jsx(RoleTableBody, __assign({}, props))] }) }));
};
