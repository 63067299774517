var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from '../common';
import { useCancelSimulation } from '../report/selectors';
export var EditScenarioDialog = function (_a) {
    var open = _a.open, to = _a.to, scenario = _a.scenario, onClose = _a.onClose;
    var t = useTranslation(['report', 'translation']).t;
    var navigate = useNavigate();
    var _b = useCancelSimulation(scenario === null || scenario === void 0 ? void 0 : scenario.id, scenario === null || scenario === void 0 ? void 0 : scenario.basePlanId), cancelSimulation = _b[1];
    var handleConfirm = function () {
        cancelSimulation({ isCancelAll: true });
        navigate(to);
    };
    return (_jsx(ConfirmDialog, { open: open, onClose: onClose, title: t('translation:confirmation.title'), message: _jsx(Typography, __assign({ style: { whiteSpace: 'pre-line' } }, { children: t('translation:confirmation.yesEditScenario') })), onConfirm: handleConfirm, confirmButtonText: 'continue' }));
};
