var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, TextField, Typography, styled } from '@mui/material';
import { useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { FormField } from '../../common';
import { useQueueByIds } from '../selectors';
import { CRITERIAL_FIELDS } from './attentionDialog';
import { SingleSkillItem } from './singleSkill';
var MultiGroup = styled(Stack, {
    shouldForwardProp: function (prop) { return prop !== 'isDraggingOver'; },
})(function (_a) {
    var theme = _a.theme, isDraggingOver = _a.isDraggingOver;
    return ({
        background: theme.palette.primary.light,
        padding: theme.spacing(2, 2, 5),
        border: "2px dashed",
        width: theme.typography.pxToRem(400),
        minHeight: theme.typography.pxToRem(280),
        height: '100%',
        borderColor: isDraggingOver ? theme.palette.success.main : theme.palette.grey[600],
        cursor: isDraggingOver ? 'copy' : 'default',
    });
});
export var MultiSkillGroup = function (_a) {
    var index = _a.index, groupData = _a.groupData, name = _a.name, control = _a.control, draggingId = _a.draggingId, onBusinessAreaChange = _a.onBusinessAreaChange;
    var t = useTranslation('multiSkill', { keyPrefix: 'add' }).t;
    var isEmpty = !groupData.children.length;
    var childQueues = useQueueByIds(groupData.children);
    var sortedChildren = useMemo(function () { return __spreadArray([], groupData.children, true).sort(function (f, s) { return f - s; }); }, [groupData.children]);
    var isDropDisabled = useMemo(function () {
        if (!draggingId)
            return false;
        return childQueues.some(function (childQueue) { return childQueue && childQueue.id !== draggingId && !childQueue.matches.includes(draggingId); });
    }, [childQueues, draggingId]);
    return (_jsx(Droppable, __assign({ droppableId: "".concat(index), isDropDisabled: isDropDisabled }, { children: function (provided, snapshot) { return (_jsxs(Stack, __assign({ display: "flex", flexDirection: "column", gap: 4, position: "relative" }, { children: [_jsx(FormField, __assign({ name: "".concat(name, ".businessArea"), control: control }, { children: _jsx(TextField, { disabled: isEmpty, inputProps: {
                            maxLength: 128,
                        }, onChange: function (e) { return onBusinessAreaChange(index, e.target.value); } }) })), _jsx(Stack, __assign({ flex: 1, ref: provided.innerRef }, provided.droppableProps, { children: _jsxs(MultiGroup, __assign({ isDraggingOver: snapshot === null || snapshot === void 0 ? void 0 : snapshot.isDraggingOver, justifyContent: isEmpty ? 'center' : '', alignItems: isEmpty ? 'center' : '' }, { children: [isDropDisabled && (_jsxs(Stack, __assign({ display: "flex", flexDirection: "column", color: "textSecondary", gap: 4, p: 3, position: "absolute" }, { children: [_jsx(FontAwesomeIcon, { icon: faBan }), _jsxs(Typography, __assign({ variant: "body2" }, { children: [t('message'), " "] })), _jsx(Stack, __assign({ pl: 4 }, { children: CRITERIAL_FIELDS.map(function (item) { return (_jsx(Typography, __assign({ component: "li", variant: "body2" }, { children: t(item) }), item)); }) }))] }))), _jsx(Stack, __assign({ visibility: !isDropDisabled ? 'visible' : 'hidden' }, { children: sortedChildren.map(function (childId, idx) { return (_jsx(SingleSkillItem, { queueId: childId, index: idx }, childId)); }) })), isEmpty && !(snapshot === null || snapshot === void 0 ? void 0 : snapshot.isDraggingOver) && (_jsx(Typography, __assign({ variant: "body2", color: "textSecondary" }, { children: t('dragAndDrop') }))), provided.placeholder] })) }))] }))); } })));
};
