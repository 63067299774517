var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Step as MuiStep, StepConnector, StepIcon, StepLabel, Stepper, stepConnectorClasses, stepIconClasses, stepLabelClasses, styled, } from '@mui/material';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../app/selectors';
import { ConfirmDialog } from '../common/confirmDialog';
import { STEPS_LENGTH, STEP_NAME } from './constants';
import { WizardContext } from './context';
import { useWizardParams } from './selectors';
var StyledStepper = styled(Stepper)(function (_a) {
    var theme = _a.theme;
    return ({
        paddingTop: theme.typography.pxToRem(14),
        paddingBottom: theme.typography.pxToRem(14),
        backgroundColor: theme.palette.common.white,
    });
});
var StyledStepLabel = styled(StepLabel)(function (_a) {
    var _b, _c;
    var theme = _a.theme;
    return (_b = {
            fontSize: theme.typography.pxToRem(20),
            fontWeight: 700
        },
        _b[".".concat(stepIconClasses.root)] = {
            borderRadius: '50%',
            border: "".concat(theme.typography.pxToRem(1), " solid ").concat(theme.palette.grey[500]),
            width: '2rem',
            height: '2rem',
            fill: 'none',
        },
        _b[".".concat(stepIconClasses.active)] = (_c = {
                fill: theme.palette.text.primary,
                borderWidth: 0
            },
            _c[".".concat(stepIconClasses.text)] = {
                fill: theme.palette.common.white,
            },
            _c),
        _b[".".concat(stepIconClasses.text)] = {
            fill: theme.palette.text.primary,
        },
        _b[".".concat(stepLabelClasses.label, ".").concat(stepLabelClasses.alternativeLabel)] = {
            marginTop: theme.spacing(1),
        },
        _b[".".concat(stepLabelClasses.alternativeLabel, " .").concat(stepLabelClasses.completed)] = {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            cursor: 'pointer',
        },
        _b[".".concat(stepLabelClasses.alternativeLabel, " .").concat(stepLabelClasses.active)] = {
            textDecoration: 'unset',
            color: theme.palette.text.primary,
            fontWeight: 700,
        },
        _b);
});
var StyledStepConnector = styled(StepConnector)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            top: theme.typography.pxToRem(16)
        },
        _b[".".concat(stepConnectorClasses.line)] = {
            borderTopStyle: 'dashed',
            borderTopWidth: theme.typography.pxToRem(4),
        },
        _b);
});
var CircleStep = function (props) { return _jsx(StepIcon, __assign({}, props, { completed: false })); };
var Step = function (_a) {
    var value = _a.value, props = __rest(_a, ["value"]);
    var stepName = STEP_NAME[value];
    var t = useTranslation(['wizard', 'translation']).t;
    var step = useWizardParams().step;
    var _b = useContext(WizardContext), handleSave = _b.handleSave, goToStep = _b.goToStep, isDirty = _b.methods.formState.isDirty;
    var context = usePlatformContext();
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var handleClick = function () {
        if (isDirty && step > value) {
            setOpen(true);
            return;
        }
        if (step > value) {
            handleSave('goToStep', function () { return goToStep(value); })();
        }
    };
    var handleConfirm = function () {
        setOpen(false);
        if (step > value) {
            handleSave('goToStep', function () { return goToStep(value); })();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(MuiStep, __assign({}, props, { completed: step > value }, { children: _jsx(StyledStepLabel, __assign({ StepIconComponent: CircleStep, sx: { fontSize: 20 }, color: "inherit", onClick: handleClick }, { children: t("".concat(stepName, ".name"), { context: context }) })) })), isDirty && (_jsx(ConfirmDialog, { open: open, onClose: function () { return setOpen(false); }, title: t('translation:confirmation.unsavedChange'), message: t('translation:confirmation.confirmExit'), confirmButtonText: "saveContinue", skipSaveButtonText: "skipSave", onConfirm: handleConfirm, onSkipSaveConfirm: function () { return goToStep(value); } }))] }));
};
export var Steps = function () {
    var step = useWizardParams().step;
    return (_jsx(StyledStepper, __assign({ nonLinear: true, alternativeLabel: true, activeStep: step, connector: _jsx(StyledStepConnector, {}) }, { children: _.times(STEPS_LENGTH).map(function (s) { return (_jsx(Step, { value: s }, s)); }) })));
};
