var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrgType } from '../../enum';
import { useAPI } from '../../state';
import { useAsyncDownload } from '../hooks';
var USERS_API = '/api/user';
var USER_API = '/api/user/{id}/{orgType}';
var ADD_ORG_API = '/api/org';
var GET_ORG_API = '/api/org?orgType={orgType}';
var VERSION_API = 'api/version';
var RESET_PASSWORD_API = '/api/user/resetPassword/{id}';
var CHANGE_STATUS_API = '/api/user/changeStatus';
var CREATE_EXPORT_LOG_API = '/api/report/audit/export';
var CREATE_BILLING_LOG_API = '/api/report/billing/export';
export var useUsersAPI = function () {
    return useAPI(USERS_API);
};
export var useUserAPI = function (id, orgType) {
    return useAPI(USER_API, { id: id, orgType: orgType });
};
export var useCompaniesAPI = function (orgType) {
    if (orgType === void 0) { orgType = OrgType.Cinareo; }
    return useAPI(GET_ORG_API, { orgType: orgType });
};
export var useAddCompanyAPI = function () {
    return useAPI(ADD_ORG_API, {}, 'POST');
};
export var useVersionsAPI = function () {
    return useAPI(VERSION_API);
};
export var useCreateUserAPI = function () {
    return useAPI(USERS_API, {}, 'POST');
};
export var useUpdateUserAPI = function () {
    return useAPI(USERS_API, {}, 'PATCH');
};
export var useResetPasswordAPI = function (id) {
    return useAPI(RESET_PASSWORD_API, { id: id }, 'PUT');
};
export var useChangeStatusAPI = function () {
    return useAPI(CHANGE_STATUS_API, {}, 'PUT');
};
export var useCreateExportLogAPI = function () {
    return useAsyncDownload(CREATE_EXPORT_LOG_API);
};
export var useCreateBillingLogAPI = function () {
    return useAsyncDownload(CREATE_BILLING_LOG_API);
};
export var useSelectOrganizations = function (orgType) {
    var t = useTranslation('manageUser').t;
    var _a = useCompaniesAPI(orgType), _b = _a[0], data = _b.data, error = _b.error, requesting = _b.requesting, fetchCompanies = _a[1], clearCompanies = _a[2];
    var organizations = useMemo(function () { return __spreadArray([{ id: -1, name: t('billingLog.allCompanies') }], (data !== null && data !== void 0 ? data : []), true); }, [data, t]);
    return [{ data: organizations, error: error, requesting: requesting }, fetchCompanies, clearCompanies];
};
