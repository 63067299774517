var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faChartSimple, faCoins } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, styled } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';
import { PlanType } from '../../enum';
import { REPORT_ROUTER_URL } from '../../router/constants';
import { useUserPermission } from '../app/selectors';
import { ErrorMessageBox, ExitBar, Outlet, Tab, Tabs } from '../common';
import { useSimulatingInProgress } from '../toastify/selector';
import { useDisabledFinancial, useReportType, useSelectedTab } from './hooks';
import { ProgressToastContent } from './progressToastContent';
import { DisplayMode } from './resourcing';
import { useDisplayModeParams, useExistMultiskillMode, useIsViewOnlyPlan, useIsViewOnlyPlanAPI, useReportAPI, useViewTypeParams, } from './selectors';
var StyledTabsHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});
var StyledIcon = styled(FontAwesomeIcon)(function (_a) {
    var theme = _a.theme;
    return ({
        color: theme.palette.primary.main,
    });
});
var ButtonGroups = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        gap: theme.spacing(3),
        marginBottom: theme.spacing(2),
    });
});
export var useTabRoutes = function () {
    var _a;
    var permission = useUserPermission();
    var reportData = useReportAPI()[0];
    var viewType = useViewTypeParams()[0];
    var isReady = !!permission && !!reportData.data;
    var isDisableFinancial = useDisabledFinancial(viewType);
    var tabRoutes = useMemo(function () {
        var _a;
        return [
            {
                path: REPORT_ROUTER_URL.Resourcing,
                title: 'resourcing.title',
                icon: faChartSimple,
            },
            {
                path: REPORT_ROUTER_URL.Financial,
                title: 'financial.title',
                icon: faCoins,
                disabled: isDisableFinancial,
            },
            {
                path: REPORT_ROUTER_URL.Training,
                title: 'trainingRecruitment.title',
                icon: 'users',
                disabled: !(permission === null || permission === void 0 ? void 0 : permission.recruitmentAndTrainingPlanner) || ((_a = reportData.data) === null || _a === void 0 ? void 0 : _a.planType) !== PlanType.Monthly,
            },
        ];
    }, [isDisableFinancial, permission === null || permission === void 0 ? void 0 : permission.recruitmentAndTrainingPlanner, (_a = reportData.data) === null || _a === void 0 ? void 0 : _a.planType]);
    return [tabRoutes, isReady];
};
var ReportTabs = function () {
    var t = useTranslation('report').t;
    var _a = useIsViewOnlyPlanAPI(), fetchIsViewOnlyPlan = _a[1], clearIsViewOnlyPlan = _a[2];
    var _b = useTabRoutes(), tabRoutes = _b[0], isReady = _b[1];
    var navigate = useNavigate();
    var value = useSelectedTab();
    var viewType = useViewTypeParams()[0];
    var displayMode = useDisplayModeParams()[0];
    var reportType = useReportType();
    var existMultiskillMode = useExistMultiskillMode();
    useEffect(function () {
        fetchIsViewOnlyPlan();
        return clearIsViewOnlyPlan;
    }, [clearIsViewOnlyPlan, fetchIsViewOnlyPlan]);
    useEffect(function () {
        var activeTab = tabRoutes.find(function (route) { return route.path === value; });
        if (isReady && (activeTab === null || activeTab === void 0 ? void 0 : activeTab.disabled)) {
            navigate(REPORT_ROUTER_URL.Resourcing, { replace: true });
        }
    }, [isReady, navigate, tabRoutes, value]);
    return (_jsx(Tabs, __assign({ value: value }, { children: tabRoutes.map(function (route) {
            var toRoute = route.path;
            if (route.path !== REPORT_ROUTER_URL.Training) {
                toRoute += "/".concat(reportType, "?viewType=").concat(viewType);
                if (existMultiskillMode) {
                    toRoute += "&displayMode=".concat(displayMode);
                }
            }
            return (_jsx(Tab, { component: Link, icon: _jsx(StyledIcon, { icon: route.icon, size: "lg" }), iconPosition: "start", value: route.path, to: toRoute, label: _jsxs(Typography, __assign({ variant: "subtitle2" }, { children: ["\u00A0", t(route.title)] })), disabled: route.disabled, "data-testid": route.title }, route.path));
        }) })));
};
export var ReportLayout = function () {
    var _a;
    var t = useTranslation().t;
    var isViewOnly = useIsViewOnlyPlan();
    var _b = useReportAPI(), reportData = _b[0], clearReportData = _b[2];
    var _c = useState(), onPrint = _c[0], setOnPrint = _c[1];
    var _d = useState(false), isLoadingPrint = _d[0], setIsLoadingPrint = _d[1];
    var _e = useState(), onExport = _e[0], setOnExport = _e[1];
    var _f = useState(false), isLoadingExport = _f[0], setIsLoadingExport = _f[1];
    var basePlanId = useParams().basePlanId;
    var simulatingInProgress = useSimulatingInProgress(basePlanId);
    var displayMode = useDisplayModeParams()[0];
    var isSimulating = !!(simulatingInProgress === null || simulatingInProgress === void 0 ? void 0 : simulatingInProgress.length);
    useEffect(function () {
        return clearReportData;
    }, [clearReportData]);
    useEffect(function () {
        if (isSimulating) {
            var toastId_1 = toast.info(_jsx(ProgressToastContent, {}), {
                autoClose: false,
                position: 'bottom-right',
                theme: 'colored',
                closeButton: false,
                closeOnClick: false,
                transition: Slide,
                icon: false,
            });
            return function () { return toast.dismiss(toastId_1); };
        }
    }, [isSimulating, t, basePlanId]);
    return (_jsxs(_Fragment, { children: [_jsx(ErrorMessageBox, { errors: [reportData.error], sx: { margin: 3, marginBottom: 0 } }), _jsx(ExitBar, { title: (_a = reportData.data) === null || _a === void 0 ? void 0 : _a.name }), _jsx(Box, __assign({ sx: { bgcolor: 'common.white', padding: '1rem 1.25rem 0' } }, { children: _jsxs(StyledTabsHeader, { children: [_jsx(ReportTabs, {}), _jsxs(ButtonGroups, { children: [_jsx(LoadingButton, __assign({ variant: "outlined", disabled: !onExport || isViewOnly, onClick: function () { return onExport === null || onExport === void 0 ? void 0 : onExport(); }, loading: isLoadingExport }, { children: t('action.export') })), _jsx(LoadingButton, __assign({ variant: "outlined", disabled: !onPrint || isViewOnly || displayMode !== DisplayMode.Single, onClick: function () { return onPrint === null || onPrint === void 0 ? void 0 : onPrint(); }, loading: isLoadingPrint }, { children: t('action.print') }))] })] }) })), !reportData.error && _jsx(Outlet, { context: { setOnPrint: setOnPrint, setOnExport: setOnExport, setIsLoadingPrint: setIsLoadingPrint, setIsLoadingExport: setIsLoadingExport } })] }));
};
