var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faSquarePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, IconButton, Link, List, Stack, Typography, styled } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SubTitle } from '../../layout/styled';
import { Completed } from '../../wizard/queue/types';
import { Actions } from '../actions';
import { useSingleSkills } from '../selectors';
import { MultiSkillStep, MultiskillType } from '../types';
import { MultiSkillGroup } from './multiSkillGroup';
import { SingleSkillItem } from './singleSkill';
var StyledLeftContent = styled(Stack)(function (_a) {
    var theme = _a.theme;
    return ({
        backgroundColor: theme.palette.background.default,
        height: '100%',
        paddingBottom: theme.spacing(4),
    });
});
var validateForm = function (queueNames) {
    return yup.object().shape({
        multiSkillGroups: yup.array().of(yup.object().shape({
            businessArea: yup
                .string()
                .max(128)
                .trim()
                .when('children', {
                is: function (children) { return !!children.length; },
                then: function (schema) {
                    return schema
                        .required()
                        .specialCharacter()
                        .quotesNotAllowed()
                        .test('unique', 'Duplicated', function (value, context) {
                        var _a, _b, _c;
                        var path = context.path;
                        var multiSkillGroups = ((_a = context.from) === null || _a === void 0 ? void 0 : _a[1].value).multiSkillGroups;
                        var currentIndex = parseInt((_c = (_b = /\d+/.exec(path)) === null || _b === void 0 ? void 0 : _b[0]) !== null && _c !== void 0 ? _c : '-1', 10);
                        var isUniqueInSingleSkills = !queueNames.includes(value);
                        return (isUniqueInSingleSkills &&
                            !multiSkillGroups
                                .filter(function (group, index) { return index !== currentIndex && !!group.children.length; })
                                .some(function (group) { return group.businessArea.trim() === value; }));
                    });
                },
            })
                .label('Name'),
            children: yup
                .array()
                .test('children-length', 'Group must have at least 2 queues', function (value) { return !(value === null || value === void 0 ? void 0 : value.length) || value.length > 1; }),
        })),
    });
};
var getDefaultGroupValue = function (businessArea) { return ({
    businessArea: businessArea,
    children: [],
    maxOccupancy: null,
    isUseWAP: Completed.No,
    multiskillType: MultiskillType.All,
}); };
export var AddSingleSkill = function (_a) {
    var multiSkillGroups = _a.multiSkillGroups, setMultiSkillGroups = _a.setMultiSkillGroups, goToStep = _a.goToStep;
    var t = useTranslation('multiSkill').t;
    var singleQueues = useSingleSkills();
    var queueNames = useMemo(function () { return singleQueues.map(function (q) { return q.name; }); }, [singleQueues]);
    var _b = useState(), shouldScrollIntoView = _b[0], setShouldScrollIntoView = _b[1];
    var groupScrollRef = useRef(null);
    var _c = useState(), draggingId = _c[0], setDraggingId = _c[1];
    var defaultValues = useMemo(function () { return ({
        multiSkillGroups: __spreadArray(__spreadArray([], multiSkillGroups, true), _.times(Math.max(3 - multiSkillGroups.length, 1), function (n) {
            return getDefaultGroupValue("".concat(t('add.defaultNameGroup'), " ").concat(multiSkillGroups.length + n + 1));
        }), true),
    }); }, [multiSkillGroups, t]);
    var _d = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm(queueNames)),
    }), handleSubmit = _d.handleSubmit, control = _d.control, reset = _d.reset, watch = _d.watch, setValue = _d.setValue, trigger = _d.trigger;
    var groups = watch('multiSkillGroups');
    useEffect(function () {
        reset(defaultValues);
    }, [defaultValues, reset]);
    var handleAddGroup = function () {
        setValue("multiSkillGroups.".concat(groups.length), getDefaultGroupValue("".concat(t('add.defaultNameGroup'), " ").concat(groups.length + 1)));
        setShouldScrollIntoView(true);
    };
    useEffect(function () {
        if (shouldScrollIntoView && groupScrollRef.current) {
            groupScrollRef.current.scrollTo({ left: groupScrollRef.current.scrollWidth, behavior: 'smooth' });
            setShouldScrollIntoView(false);
        }
    }, [shouldScrollIntoView]);
    var handleDragUpdate = function (update) {
        setDraggingId(+update.draggableId);
    };
    var handleDragEnd = function (result) {
        setDraggingId(undefined);
        var source = result.source, destination = result.destination, draggableId = result.draggableId;
        var sInd = +source.droppableId;
        if (!destination)
            return;
        var dInd = +destination.droppableId;
        if (sInd === dInd)
            return;
        setValue('multiSkillGroups', __spreadArray([], groups, true));
        if (groups[sInd]) {
            setValue("multiSkillGroups.".concat(sInd, ".children"), groups[sInd].children.filter(function (id) { return id !== +draggableId; }));
            trigger("multiSkillGroups.".concat(sInd, ".businessArea"));
        }
        if (groups[dInd]) {
            setValue("multiSkillGroups.".concat(dInd, ".children.").concat(groups[dInd].children.length), +draggableId);
            trigger("multiSkillGroups.".concat(dInd, ".businessArea"));
        }
    };
    var handleFormSubmit = handleSubmit(function (data) {
        setMultiSkillGroups(data.multiSkillGroups.filter(function (g) { return g.children.length; }));
        window.scrollTo(0, 0);
        goToStep(MultiSkillStep.Input);
    });
    var disabledNext = useMemo(function () {
        return (groups === null || groups === void 0 ? void 0 : groups.every(function (group) { return !group.children.length; })) ||
            (groups === null || groups === void 0 ? void 0 : groups.some(function (group) { return group.children.length && group.children.length < 2; }));
    }, [groups]);
    var children = useMemo(function () { return groups.flatMap(function (group) { return group.children; }); }, [groups]);
    var handleBusinessAreaChange = function (index, value) {
        setValue("multiSkillGroups.".concat(index, ".businessArea"), value);
        trigger('multiSkillGroups');
    };
    return (_jsxs(Box, __assign({ my: 6, gap: 4, component: "form", onSubmit: handleFormSubmit, onReset: function () { return reset(); } }, { children: [_jsx(SubTitle, { children: t('add.description') }), _jsxs(Grid, __assign({ container: true, direction: "row", gap: 4, wrap: "nowrap" }, { children: [_jsxs(DragDropContext, __assign({ onDragEnd: handleDragEnd, onDragUpdate: handleDragUpdate }, { children: [_jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsxs(StyledLeftContent, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsxs(Stack, __assign({ bgcolor: "common.white", display: "flex", flexDirection: "row", gap: 4, pb: 5.5 }, { children: [_jsx(Typography, __assign({ variant: "subtitle1" }, { children: t('add.singleSkills') })), _jsx(Link, __assign({ component: "button", type: "reset", variant: "body2" }, { children: t('add.resetAll') }))] })), _jsx(Droppable, __assign({ droppableId: "-1" }, { children: function (provided) { return (_jsxs(List, __assign({ ref: provided.innerRef }, provided.droppableProps, { sx: { height: '100%' } }, { children: [singleQueues
                                                        .filter(function (item) { return !children.includes(item.id); })
                                                        .map(function (item, index) { return (_jsx(SingleSkillItem, { queueId: item.id, index: index }, item.id)); }), provided.placeholder] }))); } }))] })) })), _jsx(Grid, __assign({ item: true, xs: 8.5 }, { children: _jsx(Stack, __assign({ display: "flex", flexDirection: "row", gap: 4, height: "100%", overflow: "auto", ref: groupScrollRef }, { children: groups.map(function (group, index) {
                                        var _a;
                                        return (_jsx(MultiSkillGroup, { index: index, groupData: group, name: "multiSkillGroups.".concat(index), control: control, draggingId: draggingId, onBusinessAreaChange: handleBusinessAreaChange }, (_a = group.id) !== null && _a !== void 0 ? _a : index));
                                    }) })) }))] })), _jsx(Grid, __assign({ item: true, xs: 0.5, display: "flex", alignItems: "center" }, { children: _jsx(IconButton, __assign({ color: "primary", onClick: handleAddGroup }, { children: _jsx(FontAwesomeIcon, { size: "xl", icon: faSquarePlus }) })) }))] })), _jsx(Actions, { disabled: disabledNext })] })));
};
