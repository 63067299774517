var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { FallbackComponent } from '../../common';
import { stringToNumber } from '../../common/numberInput/hooks';
import { FormlessProvider } from '../../hooks/useFormlessContext';
import { useReportAPI } from '../../report/selectors';
import { useToastMessagesAPI } from '../../toastify/selector';
import { pickNumberValues, selectShrinkageValues, selectSupportStaffValues } from '../../wizard/useDefaultValue';
import { WizardSimpleProvider } from '../../wizard/useWizardSimpleContext';
import { generateValidateField, shrinkageShape, staffShape } from '../../wizard/validateForm';
import { Actions } from '../actions';
import { useMultiSkillParams } from '../selectors';
import { MultiSkillStep } from '../types';
import { MultiSkillGroupDetail } from './multiSkillGroupDetail';
import { MultiSkillGroups } from './multiSkillGroups';
import { useGroupDetailAPI, useMultiSkillAPI, useSimulationAPI } from './selectors';
var validateForm = yup.object().shape(__assign(__assign(__assign({ maxOccupancy: yup.number().nullable() }, shrinkageShape), staffShape), { volumes: generateValidateField('rate', 'attrition'), multiplier: yup.number().required(), trainWeeks: yup.number().required() }));
export var MultiSkillGroup = function (_a) {
    var _b;
    var multiSkillGroups = _a.multiSkillGroups, setMultiSkillGroups = _a.setMultiSkillGroups, goToStep = _a.goToStep;
    var _c = useState(0), activeGroup = _c[0], setActiveGroup = _c[1];
    var defaultValues = useMemo(function () { return multiSkillGroups[activeGroup]; }, [activeGroup, multiSkillGroups]);
    var planData = useReportAPI()[0];
    var _d = useGroupDetailAPI(multiSkillGroups[activeGroup]), queueData = _d[0], fetchQueueData = _d[1], clearQueueData = _d[2];
    var _e = useMultiSkillAPI(), multiskillData = _e[0], fetchMultiskillData = _e[1], clearMultiskillData = _e[2];
    var _f = useSimulationAPI(), simulationData = _f[0], fetchSimulationData = _f[1], clearSimulationData = _f[2];
    var _g = useToastMessagesAPI(), fetchToastMessagesData = _g[1];
    var basePlanId = useMultiSkillParams().basePlanId;
    var navigate = useNavigate();
    var _h = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validateForm),
    }), reset = _h.reset, handleSubmit = _h.handleSubmit, isValid = _h.formState.isValid, control = _h.control, setValue = _h.setValue, getValues = _h.getValues;
    var queue = useWatch({ name: '_raw', control: control });
    var isActionLoading = !!multiskillData.requesting || !!simulationData.requesting;
    useEffect(function () {
        if (!multiSkillGroups.length) {
            goToStep(MultiSkillStep.Add);
        }
    }, [goToStep, multiSkillGroups.length]);
    useEffect(function () {
        fetchQueueData();
        return clearQueueData;
    }, [clearQueueData, fetchQueueData]);
    useEffect(function () {
        if (multiskillData.data) {
            fetchSimulationData();
        }
        return clearMultiskillData;
    }, [clearMultiskillData, fetchSimulationData, multiskillData.data]);
    useEffect(function () {
        if (simulationData.data) {
            navigate("/report/".concat(basePlanId));
            fetchToastMessagesData();
        }
        return clearSimulationData;
    }, [basePlanId, clearSimulationData, fetchToastMessagesData, navigate, simulationData.data]);
    useEffect(function () {
        reset(defaultValues);
    }, [defaultValues, reset]);
    useEffect(function () {
        if (queueData.data) {
            var shrinkages = selectShrinkageValues(queueData.data);
            var roles = selectSupportStaffValues(queueData.data);
            var volumes = _.map(queueData.data.volumes, function (v) { return pickNumberValues(v, [], 'rate', 'attrition'); });
            reset(__assign(__assign(__assign(__assign({ _raw: queueData.data }, multiSkillGroups[activeGroup]), shrinkages), roles), { volumes: volumes, multiplier: stringToNumber(queueData.data.multiplier), trainWeeks: stringToNumber(queueData.data.trainWeeks.toString()) }));
        }
    }, [activeGroup, multiSkillGroups, queueData.data, reset]);
    var handleFormSubmit = handleSubmit(function (data) {
        var newData = __spreadArray([], multiSkillGroups, true);
        newData[activeGroup] = data;
        fetchMultiskillData({
            items: newData.map(function (item) { return (__assign(__assign({}, item), { shrinkages: _.flatten(item.shrinkages), multiskillType: Number(item.multiskillType), isUseWAP: Number(item.isUseWAP), _children: undefined, _raw: undefined })); }),
        });
    });
    var setGroupValues = function (values) {
        setMultiSkillGroups(multiSkillGroups.map(function (item, index) { return (index === activeGroup ? __assign(__assign({}, values), { _children: values.children }) : item); }));
    };
    var handleChangeActiveGroup = function (value) {
        return handleSubmit(function (values) {
            setActiveGroup(value);
            setGroupValues(values);
        })();
    };
    var handleModifyGroups = function () {
        if (isValid) {
            setGroupValues(getValues());
        }
        goToStep(MultiSkillStep.Add);
    };
    return (_jsxs(Box, __assign({ my: 6, gap: 4, component: "form", onSubmit: handleFormSubmit }, { children: [_jsxs(Grid, __assign({ container: true, direction: "row", gap: 8, wrap: "nowrap" }, { children: [_jsx(Grid, __assign({ item: true, xs: 3, lg: 3 }, { children: _jsx(MultiSkillGroups, { data: multiSkillGroups, value: activeGroup, isValid: isValid, onChange: handleChangeActiveGroup, onModify: handleModifyGroups }) })), _jsxs(Grid, __assign({ item: true, xs: 9 }, { children: [_jsxs(Stack, __assign({ direction: "row", gap: 2, mb: 5, alignItems: "center" }, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: (_b = multiSkillGroups[activeGroup]) === null || _b === void 0 ? void 0 : _b.businessArea })), _jsx(Actions, { mt: 0, loading: isActionLoading })] })), _jsx(FormlessProvider, __assign({ control: control, setValue: setValue }, { children: _jsx(WizardSimpleProvider, __assign({ plan: planData.data, queue: queue }, { children: _jsx(FallbackComponent, __assign({ requesting: !!queueData.requesting }, { children: _jsx(MultiSkillGroupDetail, { activeData: defaultValues }) })) })) }))] }))] })), _jsx(Actions, { loading: isActionLoading })] })));
};
